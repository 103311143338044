import React from "react";

const Button = () => {
  return (
    <button className="bg-indigo-500 text-white hover:text-stone-200 px-6 py-2 rounded-full">
      <a
        href="https://t.me/SCIENTIFICRESEARCHERUZ"
        className="mr-5 "
      >
        Maqola yuborish
      </a>
    </button>
  );
};

export default Button;
