// src/App.js
import React, { useEffect, useState } from "react";
import {useParams} from 'react-router-dom'
import axios from "axios";

function Visitors() {
  const [count, setCount] = useState(0);
  const { id } = useParams();

  useEffect(() => {
    // Increment the count on the server as soon as the page loads
    axios
      .post(`http://localhost:8000/api/increment${id}`)
      .then(() => {
        // Fetch the updated count from the server
        axios
          .get(`http://localhost:8000/api/count${id}`)
          .then((response) => {
            setCount(response.data.count);
          })
          .catch((error) => {
            console.error("There was an error fetching the count!", error);
          });
      })
      .catch((error) => {
        console.error("There was an error incrementing the count!", error);
      });
  }, [id]);

  return (
    <div className="pagecounter">
      {count}
    </div>
  );
}

export default Visitors;
