import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { Visitors } from "../components";

function ScientificNews() {
  const [article, setArticle] = useState([]);
  const [count, setCount] = useState(0);
  console.log(article);

  useEffect(() => {
    const fetcharticle = async () => {
      try {
        const response = await axios.get(
          "https://server1.masterdev.uz/api/articles"
        );
        setArticle(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetcharticle();
  }, []);

  // useEffect(() => {
  //   const fetchcounter = async () => {
  //     try {
  //       const response = await axios.get(
  //         "http://localhost:8000/api/count"
  //       );
  //       setCount(response.data);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };
  //   fetchcounter();
  // }, []);
  // const posts = [
  //   {
  //     id: 1,
  //     title: "Sun’iy intellektga ega robot xavfli zonalarga yuboriladi",
  //     img: "https://media.istockphoto.com/id/1452604857/photo/businessman-touching-the-brain-working-of-artificial-intelligence-automation-predictive.jpg?s=1024x1024&w=is&k=20&c=Mz2G15YAcE09_ywaRc43p9jmG2urk69uqyopTbaG4cI=",
  //     href: "#",
  //     description:
  //       "Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.",
  //     date: "Mar 16, 2020",
  //     datetime: "2020-03-16",
  //     category: { title: "Suniy intellekt", href: "#" },
  //     author: {
  //       name: "Michael Foster",
  //       role: "Co-Founder / CTO",
  //       href: "#",
  //       imageUrl:
  //         "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  //     },
  //   },
  //   {
  //     id: 2,
  //     title: "Sun’iy intellektga ega robot xavfli zonalarga yuboriladi",
  //     img: "https://media.istockphoto.com/id/1452604857/photo/businessman-touching-the-brain-working-of-artificial-intelligence-automation-predictive.jpg?s=1024x1024&w=is&k=20&c=Mz2G15YAcE09_ywaRc43p9jmG2urk69uqyopTbaG4cI=",
  //     href: "#",
  //     description:
  //       "Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.",
  //     date: "Mar 16, 2020",
  //     datetime: "2020-03-16",
  //     category: { title: "Suniy intellekt", href: "#" },
  //     author: {
  //       name: "Michael Foster",
  //       role: "Co-Founder / CTO",
  //       href: "#",
  //       imageUrl:
  //         "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  //     },
  //   },
  //   {
  //     id: 3,
  //     title: "Sun’iy intellektga ega robot xavfli zonalarga yuboriladi",
  //     img: "https://media.istockphoto.com/id/1452604857/photo/businessman-touching-the-brain-working-of-artificial-intelligence-automation-predictive.jpg?s=1024x1024&w=is&k=20&c=Mz2G15YAcE09_ywaRc43p9jmG2urk69uqyopTbaG4cI=",
  //     href: "#",
  //     description:
  //       "Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.",
  //     date: "Mar 16, 2020",
  //     datetime: "2020-03-16",
  //     category: { title: "Suniy intellekt", href: "#" },
  //     author: {
  //       name: "Michael Foster",
  //       role: "Co-Founder / CTO",
  //       href: "#",
  //       imageUrl:
  //         "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  //     },
  //   },
  // ];

  return (
    <>
      <div className="border py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Ilmiy Yangiliklar
              {/* {count_id} */}
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              Barcha sohalardagi ilmiy yangiliklarni shu yerdan topishingiz
              mumkin
            </p>
          </div>
          <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {article?.map((value) => (
              <article
                key={value.id}
                className="flex max-w-xl flex-col items-start justify-between"
              >
                <Link to={`/currentnews/${value.id}`}>
                  <img
                    src={`https://server.masterdev.uz/uploads/${value?.img1}`}
                  />
                  <div className="flex items-center gap-x-4 text-xs mt-4">
                    <time className="text-gray-500 ">
                      {value.date.slice(0, 10)}
                    </time>
                    <FaEye />
                    
                    {/* <p>{count}</p> */}
                    <span className="relative z-10 rounded-full bg-gray-50 px-2 py-1 font-medium text-gray-600 hover:bg-gray-100">
                      {value.category}
                    </span>
                  </div>
                  <div className="group relative">
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <span className="absolute inset-0" />
                      {value.name}
                    </h3>
                  </div>
                </Link>
              </article>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ScientificNews;
