import { useEffect,useState } from "react";
import {Home, ConferencesPage, JournalsPage, ContactPage, RepubicJournals, ForeignJournals, CatJournals, ScientificNews, CurrentNews} from "./pages";
import { allJournalData, allConfData } from "./data.js";
import { Footer } from "./components";
import { Routes, Route } from "react-router-dom";
import Navbarmenu from "./components/navbar/Navbarmenu";
import {useParams} from 'react-router-dom'
import axios from "axios";
function App() {

  // const [count_id, setCount_id] = useState(0);
  // const { id } = useParams();


  // useEffect(() => {
  //   // Increment the count on the server as soon as the page loads
  //   axios
  //     .post(`http://localhost:8000/api/increment${id}`)
  //     .then(() => {
  //       // Fetch the updated count from the server
  //       axios
  //         .get(`http://localhost:8000/api/count${id}`)
  //         .then((response) => {
  //           setCount_id(response.data.count);
  //         })
  //         .catch((error) => {
  //           console.error("There was an error fetching the count!", error);
  //         });
  //     })
  //     .catch((error) => {
  //       console.error("There was an error incrementing the count!", error);
  //     });
  // }, [id]);

  return (
    <div className="App">
      <Navbarmenu />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/journalspage" element={<JournalsPage allJournalData={allJournalData} />} />
        <Route path="/conferencesPage" element={<ConferencesPage allConfData={allConfData} />}/>
        <Route path="/contactpage" element={<ContactPage />} />
        <Route path="/scientificnews" element={<ScientificNews  />} />
        <Route path="/currentnews/:id" element={<CurrentNews  />} />
        <Route path="/republicjournals/" element={<RepubicJournals />} />
        <Route path="/foreignjournals" element={<ForeignJournals />} />
        <Route path="/catjournals/:id" element={<CatJournals />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
