export const journalData = [
  {
    id: "https://journals.uznauka.uz/index.php/ojs/index",
    name: "Science and society - Наука и oбщество - Fan va jamiyat jurnali",
    category: "Ilmiy-tahliliy jurnal",
    img: "assets/img/journals/science_1.jpg",
  },
  {
    id: "https://journals.uznauka.uz/index.php/stm/index",
    name: "Scientific trends in medicine - Научные тенденции в медицине – Tibbiyotda ilmiy tendensiyalar",
    category: "Tibbiyot sohasi",
    img: "assets/img/journals/tibbiyotda_ilmiy%20tendensiyalar.jpg",
  },
  {
    id: "https://journals.uznauka.uz/index.php/ies/index",
    name: "Innovations in exact science - Инновации в точных науках – Aniq fanlarda innovatsiyalar",
    category: "Innovatsiyalar",
    img: "assets/img/journals/aniq_fanlarda_innovatsiyalar.jpg",
  },
  {
    id: "https://journals.uznauka.uz/index.php/lps/index",
    name: "Law, Policy and Society - правo, пoлитика и oбществo - huquq, siyosat va jamiyat",
    category: "Jamiyat",
    img: "assets/img/journals/huquq_siyosat.jpg",
  },
  {
    id: "https://journals.uznauka.uz/index.php/sse/index",
    name: "Social science and education - Социальные науки и образование – Ijtimoiy soha va ta'lim",
    category: "Ijtimoiy soha",
    img: "assets/img/journals/Ijtimoiy_soha_va_talim.jpg",
  },
];

export const allJournalData = [
  {
    id: "https://journals.uznauka.uz/index.php/ojs/index",
    name: "Science and society - Наука и oбщество - Fan va jamiyat jurnali",
    category: "Ilmiy-tahliliy jurnal",
    img: "assets/img/journals/science_1.jpg",
    indexing: " GoogleScholar, DOI, Zenodo, CrossRef",
    editorial: " LEGALBOX TEAM MASULIYATI CHEKLANGAN JAMIYAT",
    issn: " 2992-913X",
    doi: "",
    site: " UzNauka.uz",
  },
  {
    id: "https://journals.uznauka.uz/index.php/stm/index",
    name: "Scientific trends in medicine - Научные тенденции в медицине – Tibbiyotda ilmiy tendensiyalar",
    category: "Tibbiyot sohasi",
    img: "assets/img/journals/tibbiyotda_ilmiy%20tendensiyalar.jpg",
    indexing: " GoogleScholar, DOI, Zenodo, CrossRef",
    editorial: " LEGALBOX TEAM MASULIYATI CHEKLANGAN JAMIYAT",
    issn: "",
    doi: "",
    site: " UzNauka.uz",
  },
  {
    id: "https://journals.uznauka.uz/index.php/ies/index",
    name: "Innovations in exact science - Инновации в точных науках – Aniq fanlarda innovatsiyalar",
    category: "Innovatsiyalar",
    img: "assets/img/journals/aniq_fanlarda_innovatsiyalar.jpg",
    indexing: " GoogleScholar, DOI, Zenodo, CrossRef",
    editorial: " LEGALBOX TEAM MASULIYATI CHEKLANGAN JAMIYAT",
    issn: "",
    doi: "",
    site: " UzNauka.uz",
  },
  {
    id: "https://journals.uznauka.uz/index.php/lps/index",
    name: "Law, Policy and Society - правo, пoлитика и oбществo - huquq, siyosat va jamiyat",
    category: "Jamiyat",
    img: "assets/img/journals/huquq_siyosat.jpg",
    indexing: " GoogleScholar, DOI, Zenodo, CrossRef",
    editorial: " LEGALBOX TEAM MASULIYATI CHEKLANGAN JAMIYAT",
    issn: "",
    doi: "",
    site: " UzNauka.uz",
  },
  {
    id: "https://journals.uznauka.uz/index.php/sse/index",
    name: "Social science and education - Социальные науки и образование – Ijtimoiy soha va ta'lim",
    category: "Ijtimoiy soha",
    img: "assets/img/journals/Ijtimoiy_soha_va_talim.jpg",
    indexing: " GoogleScholar, DOI, Zenodo, CrossRef",
    editorial: " LEGALBOX TEAM MASULIYATI CHEKLANGAN JAMIYAT",
    issn: "",
    doi: "",
    site: " UzNauka.uz",
  },
];

export const confData = [
  {
    id: "https://conf.uznauka.uz/index.php/zttf/issue/view/6",
    name: "Xalqaro ilmiy pediatriya jurnali",
    category: "Pediatriya",
    img: "https://conf.uznauka.uz/public/journals/1/cover_issue_6_en.png",
    indexing: " GoogleScholar, DOI, Zenodo, CrossRef",
    editorial: " LEGALBOX TEAM MASULIYATI CHEKLANGAN JAMIYAT",
    issn: "",
    doi: "",
    site: " UzNauka.uz",
  },
  {
    id: "https://conf.uznauka.uz/index.php/zttf/issue/view/1",
    name: "O`zbekiston tibbiyot ilmi",
    category: "Tibbiyot sohasi",
    img: "https://conf.uznauka.uz/public/journals/1/cover_issue_1_en.png",
    issn: "",
    doi: "",
    site: " UzNauka.uz",
  },
  {
    id: "https://conf.uznauka.uz/index.php/zttf/issue/view/8",
    name: "AGRO INFORM МЧЖ",
    category: "Қишлоқ хўжалиги биология механизация",
    img: "https://conf.uznauka.uz/public/journals/1/cover_issue_8_en.png",
    issn: "",
    doi: "",
    site: " UzNauka.uz",
  },
  {
    id: "https://conf.uznauka.uz/index.php/zttf/issue/view/3",
    name: "ILM-FAN VA INNOVATSION RIVOJLANISH",
    category: "Innovatsiya",
    img: "https://conf.uznauka.uz/public/journals/1/cover_issue_3_en.png",
    issn: "",
    doi: "",
    site: " UzNauka.uz",
  },
  {
    id: "https://conf.uznauka.uz/index.php/zttf/issue/view/5",
    name: "O‘zbekistonda koloproktologiya va endoskopik jarrohlik",
    category: "Tibbiyot",
    img: "https://conf.uznauka.uz/public/journals/1/cover_issue_5_en.png",
    issn: "",
    doi: "",
    site: " UzNauka.uz",
  },
];

export const allConfData = [
  {
    id: "https://conf.uznauka.uz/index.php/zttf/issue/view/6",
    name: "Xalqaro ilmiy pediatriya jurnali",
    category: "Pediatriya",
    img: "https://conf.uznauka.uz/public/journals/1/cover_issue_6_en.png",
    indexing: " GoogleScholar, DOI, Zenodo, CrossRef",
    editorial: " LEGALBOX TEAM MASULIYATI CHEKLANGAN JAMIYAT",
    issn: " xxxx-xxxx",
    doi: "",
    site: " UzNauka.uz",
  },
  {
    id: "https://conf.uznauka.uz/index.php/zttf/issue/view/1",
    name: "O`zbekiston tibbiyot ilmi",
    category: "Tibbiyot sohasi",
    img: "https://conf.uznauka.uz/public/journals/1/cover_issue_1_en.png",
    indexing: " GoogleScholar, DOI, Zenodo, CrossRef",
    issn: "",
    doi: "",
    site: " UzNauka.uz",
  },
  {
    id: "https://conf.uznauka.uz/index.php/zttf/issue/view/8",
    name: "AGRO INFORM МЧЖ",
    category: "Қишлоқ хўжалиги биология механизация",
    img: "https://conf.uznauka.uz/public/journals/1/cover_issue_8_en.png",
    indexing: " GoogleScholar, DOI, Zenodo, CrossRef",
    issn: "",
    doi: "",
    site: " UzNauka.uz",
  },
  {
    id: "https://conf.uznauka.uz/index.php/zttf/issue/view/3",
    name: "ILM-FAN VA INNOVATSION RIVOJLANISH",
    category: "Innovatsiya",
    img: "https://conf.uznauka.uz/public/journals/1/cover_issue_3_en.png",
    indexing: " GoogleScholar, DOI, Zenodo, CrossRef",
    issn: "",
    doi: "",
    site: " UzNauka.uz",
  },
  {
    id: "https://conf.uznauka.uz/index.php/zttf/issue/view/5",
    name: "O‘zbekistonda koloproktologiya va endoskopik jarrohlik",
    category: "Tibbiyot",
    img: "https://conf.uznauka.uz/public/journals/1/cover_issue_5_en.png",
    indexing: " GoogleScholar, DOI, Zenodo, CrossRef",
    issn: "",
    doi: "",
    site: " UzNauka.uz",
  },
  {
    id: "https://conf.uznauka.uz/index.php/zttf/issue/view/7",
    name: "TARIX, SIYOSAT, JAMIYAT VA FALSAFANING AKTUAL MUAMMOLARI",
    category: "Falsafa",
    img: "https://conf.uznauka.uz/public/journals/1/cover_issue_7_en.png",
    indexing: " GoogleScholar, DOI, Zenodo, CrossRef",
    issn: "",
    doi: "",
    site: " UzNauka.uz",
  },
  {
    id: "https://conf.uznauka.uz/index.php/zttf/issue/view/2",
    name: "FIZIKA, TEXNIKA VA MATEMATIKA: FANLARARO ILMIY MUHOKAMALAR",
    category: "Aniq fanlar",
    img: "https://conf.uznauka.uz/public/journals/1/cover_issue_2_en.png",
    indexing: " GoogleScholar, DOI, Zenodo, CrossRef",
    issn: "",
    doi: "",
    site: " UzNauka.uz",
  },
  {
    id: "https://conf.uznauka.uz/index.php/zttf/issue/view/4",
    name: "ZAMONAVIY MENEGMENT VA IQTISODIYOTNING ILMIY VA AMALIY MUAMMOLARI",
    category: "Tibbiyot",
    img: "https://conf.uznauka.uz/public/journals/1/cover_issue_4_en.png",
    indexing: " GoogleScholar, DOI, Zenodo, CrossRef",
    issn: "",
    doi: "",
    site: " UzNauka.uz",
  },
  {
    id: "",
    name: "O‘zbekistonda koloproktologiya va endoskopik jarrohlik",
    category: "Iqtisodiyot",
    img: "https://conf.uznauka.uz/public/journals/1/cover_issue_5_en.png",
    indexing: " GoogleScholar, DOI, Zenodo, CrossRef",
    issn: "",
    doi: "",
    site: " UzNauka.uz",
  },
];

export const republicjournals = [
  {
    id: "1",
    name: "01.00.00	-	Fizika-matematika fanlari"
  },
  {
    id: "2",
    name: "02.00.00	-	Kimyo fanlari"
  },
  {
    id: "3",
    name: "03.00.00	-	Biologiya fanlari"
  },
  {
    id: "4",
    name: "04.00.00	-	Geologiya-mineralogiya fanlari"
  },
  {
    id: "5",
    name: "05.00.00	-	Texnika fanlari"
  },
  {
    id: "6",
    name: "06.00.00	-	Qishloq xo‘jaligi fanlari"
  },
  {
    id: "7",
    name: "07.00.00	-	Tarix fanlari"
  },
  {
    id: "8",
    name: "08.00.00	-	Iqtisodiyot fanlari"
  },
  {
    id: "9",
    name: "09.00.00	-	Falsafa fanlari"
  },
  {
    id: "10",
    name: "10.00.00	-	Filologiya fanlari"
  },
  {
    id: "11",
    name: "11.00.00	-	Geografiya fanlari"
  },
  {
    id: "12",
    name: "12.00.00	-	Yuridik fanlar"
  },
  {
    id: "13",
    name: "13.00.00	-	Pedagogika fanlari"
  },
  {
    id: "14",
    name: "14.00.00	-	Tibbiyot fanlari"
  },
  {
    id: "15",
    name: "15.00.00	-	Farmatsevtika fanlari"
  },
  {
    id: "16",
    name: "16.00.00	-	Veterinariya fanlari"
  },
  {
    id: "17",
    name: "17.00.00	-	San’atshunoslik fanlari"
  },
  {
    id: "18",
    name: "18.00.00	-	Arxitektura"
  },
  {
    id: "19",
    name: "19.00.00	-	Psixologiya fanlari"
  },
  {
    id: "22",
    name: "22.00.00	-	Sotsiologiya fanlari"
  },
  {
    id: "23",
    name: "23.00.00	-	Siyosiy fanlar"
  },

  {
    id: "24",
    name: "24.00.00	-	Islomshunoslik fanlari "
  },
]

export const foreignJournals = [
  {
    id: "1",
    name: "Fizika-matematika fanlari"
  },
  {
    id: "2",
    name: "Kimyo fanlari"
  },
  {
    id: "3",
    name: "Biologiya fanlari"
  },
  {
    id: "4",
    name: "Geologiya-mineralogiya fanlari"
  },
  {
    id: "5",
    name: "Texnika fanlari"
  },
  {
    id: "6",
    name: "Qishloq xo‘jaligi fanlari"
  },
  {
    id: "7",
    name: "Tarix fanlari"
  },
  {
    id: "8",
    name: "Iqtisodiyot fanlari"
  },
  {
    id: "9",
    name: "Falsafa fanlari"
  },
  {
    id: "10",
    name: "Filologiya fanlari"
  },
  {
    id: "11",
    name: "Geografiya fanlari"
  },
  {
    id: "12",
    name: "Yuridik fanlar"
  },
  {
    id: "13",
    name: "Pedagogika fanlari"
  },
  {
    id: "14",
    name: "Tibbiyot fanlari"
  },
  {
    id: "15",
    name: "Farmatsevtika fanlari"
  },
  {
    id: "16",
    name: "Veterinariya fanlari"
  },
  {
    id: "17",
    name: "San’atshunoslik fanlari"
  },
  {
    id: "18",
    name: "Arxitektura"
  },
  {
    id: "19",
    name: "Psixologiya fanlari"
  },
  {
    id: "22",
    name: "Sotsiologiya fanlari"
  },
  {
    id: "23",
    name: "Siyosiy fanlar"
  },

  {
    id: "24",
    name: "24.00.00	-	Islomshunoslik fanlari "
  },
]
