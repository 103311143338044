import React, { useState } from "react";
import { Link } from "react-router-dom";

function Conferences({ confData }) {
  return (
    <>
      <section id="conferences" className="text-gray-600 body-font ">
        <div className="container px-5 py-24 mx-auto">
          <h1 className="text-center my-10 text-5xl uppercase font-bold">
            Konferensiyalar
          </h1>
          <div className="flex flex-wrap -m-4">
            {confData.map((value) => {
              return (
                <div className="p-4 md:w-1/5 h-full" key={value.id}>
                  <div className="cursor-pointer h-full border border-dark-200 overflow-hidden">
                    <img
                      className=" md:h-50 w-full "
                      src={value.img}
                      alt="blog"
                    />
                    <Link to={value.id} target="_blank">
                      <button className=" w-full bg-indigo-500 text-white text-center py-2 px-6 m-0 focus:outline-none hover:bg-indigo-600 text-lg uppercase">
                        Batafsil
                      </button>
                    </Link>
                  </div>
                </div>
              );
            })}
            <button className="bg-indigo-500 text-white border mx-auto py-2 px-6 uppercase shadow">
              <Link to="/conferencespage">Ko'proq</Link>
            </button>
          </div>
        </div>
      </section><hr />
    </>
  );
}

export default Conferences;
