import React from "react";
import { Link } from "react-router-dom";
import { republicjournals } from "../data";

function RepubicJournals() {
  return (
    <div className="container mx-auto">
      <div className=" md:mb-8">
        <h2 className="font-sans font-bold sm:text-2xl ">
          <span className="block text-center mt-8 sm:mb-8 uppercase">
            Respublika ilmiy jurnallar
          </span>
        </h2>
      </div>
      {republicjournals.map((value) => {
        return (
          <table
            key={value.id}
            className="border-collapse border border-slate-400 w-full"
          >
            <tbody>
              <tr>
                <td className="p-5 cursor-pointer">
                  <Link to="/journalspage">{value.name}</Link>
                </td>
              </tr>
            </tbody>
          </table>
        );
      })}
    </div>
  );
}

export default RepubicJournals;
