import React from "react";
import {Hero, Journals, Conferences, Contact} from "../components";

import { journalData, confData } from "../data.js";

function Home() {
  return (
    <>
      <Hero />
      <Journals journalData={journalData} />
      <Conferences confData={confData} />
      <Contact />
    </>
  );
}

export default Home;
