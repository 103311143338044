import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios'
import { FaEye } from "react-icons/fa";
import { Visitors } from "../components";

function CurrentNews() {
    const { id } = useParams();
    const [article, setArticle] = useState([]);
    console.log(article);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const res = await axios.get(`https://server1.masterdev.uz/api/articles/${id}`)
                setArticle(res.data);
            } catch (err) {
                console.error('Error fetching product!');
            }
        };
        fetchArticles();
    }, [id]);

  return (
    <div className='container px-5 py-24 mx-auto'>
        {article?.map((value)=> {
            return (
                <div key={value.id}>
                <div  className=" top-0 inset-x-0 text-slate-700 dark:text-slate-400 flex"> 
                    {value.date.slice(0, 10)} 
                    <div className='flex auto mx-4'>
                        <div className="eyes-icons mt-1 mr-2">
                            <FaEye />
                            {/* {count_id} */}
                        </div>
                        {/* <p className=''><Visitors /></p> */}
                    </div>
                </div>
                <h1 className=" text-2xl font-extrabold tracking-tight text-slate-900 dark:text-slate-500 md:text-3xl my-5">{value.name}</h1>
                    <div className="text-center">
                    <img src={`https://server.masterdev.uz/uploads/${value?.img1}`}
                        alt={value.name} />
                    </div>
                   <p className='text-justify my-5'> {value.describtion}</p>
                </div>
            )
        })}
    </div>
  )
}

export default CurrentNews