import React, {useRef} from 'react'
import { ToastContainer, toast } from 'react-toastify';
import emailjs from '@emailjs/browser';

function Contact() {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_xozy814', 'template_2trile5', form.current, '8TAw6TONl_S64wGdM')
        .then((result) => {
            console.log(result.text);
            toast.success("Ma'lumot yuborildi!!!")
        }, (error) => {
            console.log(error.text);
            toast.error(error)
        });
    e.target.reset()
}
  return (
    <section id='contact' className="text-gray-600 body-font relative">
      <div className="absolute inset-0 bg-gray-300">
      <iframe className='' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.8952183819397!2d69.27472178968382!3d41.31114302675568!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8b2931f41f23%3A0x81095e06b654b845!2z0KHQutCy0LXRgCDQkNC80LjRgNCwINCi0LXQvNGD0YDQsA!5e0!3m2!1sru!2s!4v1703589807262!5m2!1sru!2s" width="100%" height="100%"  loading="lazy" ></iframe>
      </div>
      <div className="container px-5 py-24 mx-auto flex">
      <ToastContainer />
        <form ref={form} onSubmit={sendEmail} className="lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md">
          <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">O'z taklifingizni yuboring</h2>
          <p className="leading-relaxed mb-5 text-gray-600">Taklif va mulohazalaringizni biz bilan baham ko'ring</p>
          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
            <input type="email" id="email" name="user_name" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
          </div>
          <div className="relative mb-4">
            <label htmlFor="message" className="leading-7 text-sm text-gray-600">Message</label>
            <textarea id="message" name="message" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
          </div>
          <button type='submit' className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Yuborish</button>
        </form>
      </div>
    </section>
  )
}

export default Contact