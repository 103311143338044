import React, {useEffect, useState} from "react";

function ConferencesPage({ allConfData }) {
  console.log(allConfData);

  const [search, setSearch] = useState('id');
  const [data, setData]= useState(allConfData)

  const onSelect =(e)=> {
    console.log(e.target.value);
    setSearch(e.target.value)
  }

  const onFilter =(e)=> {
    let res = allConfData.filter((value) => `${value[search]}`.toLowerCase().includes(e.target.value.toLowerCase()))
    setData(res)
  }

  useEffect(()=> {
    window.scrollTo({ top: 0, behavior: 'smooth' });
},[])
  return (
    <div className="container mx-auto mt-10">
      <div className="md:mb-8">
        <h2 className=" font-sans font-bold  sm:text-2xl">
          <span className="block text-center mb-1 sm:mb-4 uppercase">
            Konferensiyalar
          </span>
        </h2>
      </div>

      <div className="border my-2 flex text-center shadow">
        <label htmlFor="journal" className="text-lg p-3">
          <img src="/assets/img/search.png" alt="" />
        </label>
        <select onChange={onSelect} className='text-gray-400' name="" id="">
          <option value="issn">ISSN bo'yicha</option>
          <option value="name">Nomi bo'yicha</option>
          <option value="category">Sohalar bo'yicha</option>
        </select>
        <input onChange={onFilter} type="text" className="px-2 w-100" placeholder="Qidirish" />
      </div>
      <hr />

      {
        data.length ? (
        data?.map((value) => {
        return (
          <div key={value.id} className=" shadow rounded grid lg:grid-cols-6 bg-white dark:bg-gray-900 dark:text-white my-5">
            <div className="px-8 py-4">
              <img src={value.img} />
            </div>
            <div className="px-8 py-4 col-span-3">
              <div className="mb-4 text-2xl break-words font-extrabold leading-none sm:text-2xl xl:text-2xl dark:text-gray-100">
                {value.name}
              </div>
              <div className="flex mb-1">
                <div>
                  <strong>ISSN:</strong>{value.issn}
                </div>
              </div>
              <div className="mb-3">
                <strong>Tahriryat:</strong>
                {value.editorial}
              </div>
              <div className="mb-3">
                <strong>Ilmiy soha:</strong> {value.category}
              </div>
              <div className="mb-3">
                <strong>Tashkil etilgan yili:</strong> 2023
              </div>
              <div className="mb-3">
                <strong>Indekslash:</strong>
                <span style={{  fontWeight: "700", background: 'black' }} className="p-1 rounded-full text-rose-500">
                  {value.indexing}
                </span>
              </div>
              <div className="mb-3">
                <strong>Web sayt:</strong>
                <a
                  href="https://uznauka.uz/"
                  style={{ color: "rgb(59, 130, 246)" }}
                >
                  {value.site}
                </a>
              </div>
            </div>
          </div>
        );
      })) : (
        <div className="w-full text-center" style={{height: '325px'}}>
          <h3 className="text-xl font-bold py-5">Ma'lumot topilmadi</h3>
        </div>
      )
  }
    </div>
  );
}

export default ConferencesPage;
