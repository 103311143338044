import React, { useEffect, useState } from "react";
import axios from "axios";
// import http  from'http';

function JournalsPage() {
  const [search, setSearch] = useState("id");
  const [data, setData] = useState("");

  console.log(data);

  /*****************  Jurnallarni filter qilish **************************/
  const onSelect = (e) => {
    console.log(e.target.value);
    setSearch(e.target.value);
  };
  /*****************  scroll Top *******************/
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  /*****************  funksiya Jurnallarni filter qilish  **************************/
  const onFilter = (e) => {
    let res = data.filter((value) =>
      `${value[search]}`.toLowerCase().includes(e.target.value.toLowerCase())
    );
    return setData(res);
  };

  useEffect(() => {
    const fetchjournals = async () => {
      try {
        const response = await axios.get(
          "https://server1.masterdev.uz/api/journals"
        );
        setData(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchjournals();
  }, []);

  return (
    <div className="container mx-auto mt-10  h-auto">
      <div className=" md:mb-8">
        <h2 className="font-sans font-bold sm:text-2xl ">
          <span className="block text-center mb-1 sm:mb-4 uppercase">
            Ilmiy-tahliliy jurnallar
          </span>
        </h2>
        <p className="text-gray-700 lg:text-sm dark:text-gray-100"></p>
      </div>

      <div className="border my-2 flex text-center shadow">
        <label htmlFor="journal" className="text-lg p-3">
          <img src="/assets/img/search.png" alt="" />
        </label>
        <select onChange={onSelect} className="text-gray-400" name="" id="">
          <option value="name">Nomi bo'yicha</option>
          <option value="issn">ISSN bo'yicha</option>
          <option value="category">Sohalar bo'yicha</option>
        </select>
        <input
          onChange={onFilter}
          type="text"
          className="px-2 w-100"
          placeholder="Qidirish"
        />
      </div>
      {data.length ? (
        data.map((value) => {
          return (
            <div
              key={value.id}
              className=" shadow rounded grid lg:grid-cols-6 bg-white dark:bg-gray-900 dark:text-white my-5"
            >
              <div className="px-8 py-4">
                <img
                  src={`https://server1.masterdev.uz/uploads/${value?.img}`}
                  alt={value.name}
                />
              </div>
              <div className="px-8 py-4 col-span-5">
                <div className="mb-4 ">
                  <strong>Jurnal nomi: </strong>
                  {value.name}
                </div>
                <div className="mb-2">
                  <div>
                    <strong>ISSN: </strong>
                    {value.issn}
                  </div>
                </div>
                <div className="mb-3">
                  <strong>Tahriryat: </strong>
                  {value.editorial}
                </div>
                <div className="mb-3">
                  <strong>Ilmiy soha:</strong> {value.field}
                </div>
                <div className="mb-3">
                  <strong>Tashkil etilgan yili: </strong> 2023
                </div>
                <div className="mb-3">
                  <strong>Indekslash: </strong>
                  {value.indexlash}
                </div>
                <div className="mb-3">
                  <strong>Web sayt: </strong>
                  <a
                    href="https://uznauka.uz/"
                    style={{ color: "rgb(59, 130, 246)" }}
                  >
                    {value.website}
                  </a>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="w-full text-center" style={{ height: "325px" }}>
          <h3 className="text-xl font-bold py-5">Ma'lumot topilmadi</h3>
        </div>
      )}
    </div>
  );
}

export default JournalsPage;
